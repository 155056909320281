<style scoped>
    .time{
        font-size: 14px;
        font-weight: bold;
    }
    .content{
        padding-left: 5px;
    }
</style>

<template>
  <div>
    <Divider dashed><span class="divider-text">{{name}}</span></Divider>

    <Timeline>
        <TimelineItem v-for="(node,i) in nodes" :key="i">
            <p class="time">{{node.approvalnode.name}}</p>
            <p class="content">{{node.userDescription}}</p>
        </TimelineItem>
    </Timeline>
  </div>
</template>

<script>
import { getApprovalNodeList } from '@/api/approval/processconfig'

export default {
  data () {
    return {
      nodes: [],
      typeName: ''
    }
  },
  methods: {
    initData () {
      if (!this.detailId) {
        this.nodes = []
      } else {
        getApprovalNodeList({ processId: this.detailId }).then(data => {
          this.nodes = data
          this.getDetails()
        })
      }
    },
    getDetails () {
      this.nodes.forEach(item => {
        const users = item.users
        let description = '节点审批人：'
        users.forEach(user => {
          description += user.name + '、'
        })
        item.userDescription = description.slice(0, -1)
      })
    }
  },
  computed: {
    detailId () {
      return this.$store.state.setting.detailId
    },
    name () {
      return this.$store.state.setting.name
    },
    beginUpdate () {
      return this.$store.state.setting.beginUpdate
    }
  },
  watch: {
    detailId (val) {
      this.initData()
    },
    beginUpdate () {
      this.initData()
    }
  }
}
</script>
